import React, { useState } from "react";
import { Hidden, Tooltip } from "@mui/material";
import classNames from "classnames";
import _ from "lodash";
import { getLeadStatusByStage, getProductData, getProductStageList } from "../../../services/Product/ProductService";
import { validateStatusUpdate } from "../../../services/StatusChangeValidators/statusChangeValidators";
import useStyles from "./caseProgressBarStyles";
import StatusValidationErrors from "./StatusValidationErrors";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import { SubmissionError } from "../../../services/ApiClient";
import LegalbirdIoModal from "../../Modal/LegalbirdIoModal";
import { useMutation } from "@tanstack/react-query";
import { updateResource } from "../../../services/ReactQuery/reactQueryService";
import CaseSubBarInfo from "../CaseSubBarInfo/CaseSubBarInfo";
import { AbstractCase } from "../../../types/AbstractCase";
import { ProductStage } from "../../../types/ProductStage";
import { StatusValidationError } from "../../../types/StatusValidationError";

type CaseProgressBarProps = {
  product: AbstractCase;
  refreshPage: Function;
};

const CaseProgressBar = ({ product, refreshPage }: CaseProgressBarProps) => {
  const updateMutation = useMutation(updateResource);
  const classes = useStyles({});
  const [error, setError] = useState<StatusValidationError | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [dialogStage, setDialogStage] = useState<ProductStage>();
  const stageList: ProductStage[] = getProductStageList(product);

  const getStageClasses = (product: AbstractCase, stage: ProductStage) => {
    let stageClasses = [classes.status];
    if (typeof product.stage !== "number") {
      return classNames(stageClasses);
    }
    if (stage.isActive({ product }) && stage.isClickable({ product })) {
      stageClasses.push(classes.clickable);
    }

    if (stage.stage <= product.stage) {
      stageClasses.push(product.backofficeCase.status === "lost" ? classes.activeStatusLost : classes.activeStatus);
      return classNames(stageClasses);
    }

    if (!stage.isActive({ product })) {
      stageClasses.push(classes.inActiveStatus);
    }

    return classNames(stageClasses);
  };

  const handleCloseErrorDialog = () => {
    setError(null);
  };

  const handleStageUpdate = async (stage: ProductStage) => {
    setIsLoading(true);
    if (!stage.isClickable({ product })) {
      setIsLoading(false);
      return;
    }

    const errors = validateStatusUpdate(product, stage.stage);
    if (errors.length > 0) {
      setError({
        stage: stage.label,
        error: new SubmissionError(errors, 422),
      });
      setIsLoading(false);
      return;
    }

    await updateMutation.mutateAsync(
      {
        id: product.id,
        uri: getProductData(product.productClassName, "apiUri"),
        data: {
          leadStatus: getLeadStatusByStage(product.productClassName, stage.stage),
        },
      },
      {
        onError: (error) => {
          setError({
            stage: stage.label,
            error: error,
          });
          setIsLoading(false);
        },
        onSuccess: () => refreshPage(),
      }
    );
  };

  const handleClick = async (stage: ProductStage) => {
    if (
      !stage.isClickable({ product }) ||
      !stage.isActive({ product }) ||
      stage.stage < getProductData(product.productClassName, "setBackCaseStage")
    ) {
      return;
    }

    if (
      product.stage &&
      stage.stage < product.stage &&
      stage.stage >= getProductData(product.productClassName, "setBackCaseStage")
    ) {
      setDialogStage(stage);
      setConfirmationDialogOpen(true);
      return;
    }

    await handleStageUpdate(stage);
  };

  if (!stageList) {
    return null;
  }

  return (
    <>
      <Hidden lgDown>
        {error && (
          <StatusValidationErrors error={error.error} stage={error.stage} closeDialog={handleCloseErrorDialog} />
        )}
        <div className={classes.statusBars}>
          {_.map(stageList, (stage) => (
            <Tooltip key={stage.stage} title={stage.label} onClick={() => handleClick(stage)}>
              <div className={getStageClasses(product, stage)}>
                {isLoading ? <CircularProgress size={20} /> : product.stageLog && product.stageLog[stage.stage]}
              </div>
            </Tooltip>
          ))}
        </div>
      </Hidden>
      <CaseSubBarInfo product={product} />
      <LegalbirdIoModal
        handleClose={() => setConfirmationDialogOpen(false)}
        open={confirmationDialogOpen}
        title={"Den Status wirklich zurücksetzen?"}
        submitButton={
          <Button
            variant={"contained"}
            onClick={() => {
              setConfirmationDialogOpen(false);
              if (!dialogStage) {
                return;
              }
              handleStageUpdate(dialogStage);
            }}
          >
            Status zurücksetzen
          </Button>
        }
      >
        <p className={"center"}>
          Dies kann zu unerwünschten Effekten führen wie zum Beispiel zum Versenden von E-Mails oder mehrfaches Anlegen
          von Aktivitäten!
        </p>
      </LegalbirdIoModal>
    </>
  );
};

export default CaseProgressBar;
