export const invoiceConfirmationConfig = {
  extrajudicialCoverageInvoice: {
    headline: "Außergerichtlich mit RSV abrechnen",
    text: "Die Deckungszusage für das außergerichtliche Verfahren liegt vor und es soll nun eine Rechnung an die " +
        "Versicherung geschickt werden. Wichtig: Hierzu muss unbedingt der mit der Versicherung abgeklärte vorläufige Streitwert " +
        "befüllt worden sein. Bitte im Zweifel nochmal prüfen, dass dieser korrekt eingetragen wurde. Es wird automatisch " +
        "die Rechnung erstellt, ein Buchungseintrag generiert und die Rechnung per E-Mail an die Versicherung verschickt.",
    buttonLabel: "Rechnung erstellen und verschicken",
  },
  default: {
    headline: "Rechnung erstellen",
    text: "Beim Erstellen einer Rechnung wird nicht nur eine PDF-Datei generiert, sondern auch automatisch ein Buchungseintrag verursacht und ggf. die Rechnung per E-Mail verschickt. Sind Sie sicher, dass Sie dies auslösen möchten? Im Zweifel bitte auf die Buchhaltung zugehen und offene Fragen besprechen.",
    buttonLabel: "Rechnung erstellen",
  },
};
