import React, { useEffect, useMemo, useState } from "react";
import Grid from "@mui/material/Grid";
import { Box, Checkbox, FormControlLabel } from "@mui/material";
import ButtonLoading from "../../Button/ButtonLoading";
import { progressionButtonStyle } from "./progressionButtonStyle";
import ApiClient from "../../../services/ApiClient";
import { apiGet } from "../../../services/Api/apiCall";
import moment from "moment";
import { useSnackbar } from "notistack";
import { PAID_TASK_STATUS_CREATED, PAID_TASK_STATUS_REQUESTED } from "../paidTaskStatus";
import _ from "lodash";
import { getCaseLinkByBackofficeCase } from "../../../services/Product/ProductService";
import DismissButton from "./DismissButton";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { updateResource } from "../../../services/ReactQuery/reactQueryService";
import { useCurrentUser } from "../../../provider/CurrentUserProvider";
import { isWbsLawyer } from "../../../services/paidTaskService";
import { PaidTask } from "../../../types/PaidTask";
import { LawyerCapacity } from "../../../types/LawyerCapacity";

type AcceptPaidTaskButtonProps = {
  paidTask: PaidTask;
};

const AcceptPaidTaskButton = ({ paidTask }: AcceptPaidTaskButtonProps) => {
  const [choiceConfirmed, setChoiceConfirmed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [myCapacity, setMyCapacity] = useState<LawyerCapacity>();
  const updateMutation = useMutation(updateResource);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const currentUser = useCurrentUser();
  const caseLink = useMemo(() => {
    return getCaseLinkByBackofficeCase(paidTask.backofficeCase, "/extern");
  }, [paidTask.backofficeCase]);

  useEffect(() => {
    apiGet("services/external/lawyerCapacity", null, { lawyerId: currentUser.id }).then((myCapacity) =>
      setMyCapacity(myCapacity)
    );
  }, [paidTask]);

  const hasUnacceptedRequests = useMemo(() => {
    return !paidTask.requestedUser && myCapacity && myCapacity?.requestedTasks > 0;
  }, [paidTask, myCapacity]);

  const assignPaidTask = async () => {
    setIsLoading(true);
    const tasksForUser = await ApiClient.get(
      "paid_tasks?assignee.id=" + currentUser.id + "&exists[markedAsDoneDate]=false&exists[deletedDate]=false"
    );
    if (tasksForUser["hydra:member"].length >= 10 && !isWbsLawyer(currentUser)) {
      enqueueSnackbar("Sie können maximal 10 Aufgaben gleichzeitig annehmen", {
        variant: "custom",
        isNonInteractive: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
        autoHideDuration: 3000,
      });
      setIsLoading(false);
      return;
    }

    const checkPaidTask = await apiGet("paid_tasks", paidTask.id);
    if (
      !_.includes([PAID_TASK_STATUS_CREATED, PAID_TASK_STATUS_REQUESTED], checkPaidTask.status) ||
      checkPaidTask.deletedDate
    ) {
      enqueueSnackbar("Diese Aufgabe ist nicht mehr verfügbar", {
        variant: "custom",
        isNonInteractive: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
        autoHideDuration: 3000,
      });
      return;
    }

    await updateMutation.mutateAsync({
      uri: "paid_tasks",
      id: paidTask.id,
      data: {
        assignedDate: moment().format("DD.MM.YYYY"),
        assignee: currentUser["@id"],
      },
    });
    navigate(caseLink + "/ausschreibung/" + paidTask.id);
    enqueueSnackbar("Sie haben die Aufgabe erfolgreich angenommen", {
      variant: "custom",
      buttonType: "link",
      buttonLink: getCaseLinkByBackofficeCase(paidTask.backofficeCase, "/extern"),
      buttonTarget: "_self",
      buttonText: "Zum Fall",
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
      autoHideDuration: 3000,
    });
  };

  return (
    <Grid container spacing={2} justifyContent={"center"}>
      {!hasUnacceptedRequests && (
        <Grid item xs={12}>
          <Grid container justifyContent={"center"}>
            <Grid item>
              <FormControlLabel
                labelPlacement="start"
                control={
                  <Checkbox checked={choiceConfirmed} onChange={({ target }) => setChoiceConfirmed(target.checked)} />
                }
                label={"Ich möchte diese Aufgabe verbindlich annehmen"}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid item>
        <ButtonLoading
          sx={progressionButtonStyle.button}
          variant={"contained"}
          isLoading={isLoading}
          disabled={!choiceConfirmed || hasUnacceptedRequests}
          onClick={() => {
            assignPaidTask();
            setChoiceConfirmed(false);
          }}
        >
          Aufgabe annehmen
        </ButtonLoading>
        {hasUnacceptedRequests && (
          <Box sx={{ textAlign: "center", color: "red" }}>
            Das Annehmen dieser Aufgabe ist nicht möglich, da offene Anfragen an Sie ausstehen. Bitte nehmen Sie
            zunächst die angefragten Aufgaben an.
          </Box>
        )}
        {!!paidTask.requestedUser && (
          <Box marginTop={"1rem"}>
            <DismissButton paidTask={paidTask} />
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default AcceptPaidTaskButton;
