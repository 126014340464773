import React, { ReactNode } from "react";
import { paidTasksCaseStyle } from "./paidTasksCaseStyle";
import Grid from "@mui/material/Grid";
import CircleIcon from "../../CircleIcon/CircleIcon";
import { ReportProblemOutlined, WorkOutlineOutlined } from "@mui/icons-material";
import { PAID_TASK_STATUS_ASSIGNED, PAID_TASK_STATUS_CREATED, PAID_TASK_STATUS_IN_REVIEW } from "../paidTaskStatus";
import moment from "moment";
import { PaidTask } from "../../../types/PaidTask";

type PaidTaskCaseHeadProps = {
  paidTask: PaidTask;
  actions: ReactNode;
};

const PaidTaskCaseHead = ({ paidTask, actions }: PaidTaskCaseHeadProps) => {
  const getPaidTaskStyles = () => {
    if (paidTask.status >= PAID_TASK_STATUS_IN_REVIEW || paidTask.deletedDate) {
      return paidTasksCaseStyle.done;
    }

    if (paidTask.status === PAID_TASK_STATUS_CREATED && moment().isAfter(paidTask.assignmentDeadline, "day")) {
      return paidTasksCaseStyle.overdue;
    }

    if (paidTask.status === PAID_TASK_STATUS_ASSIGNED && moment().isAfter(paidTask.doneDeadline, "day")) {
      return paidTasksCaseStyle.overdue;
    }
  };

  return (
    <Grid container>
      <Grid sx={paidTasksCaseStyle.imageGridItem} item>
        <CircleIcon icon={<WorkOutlineOutlined color={"secondary"} />} type={"muiIcon"} />
      </Grid>
      <Grid item sx={paidTasksCaseStyle.activityContent}>
        <Grid container>
          <Grid item xs={12} sx={getPaidTaskStyles()}>
            <Grid container sx={paidTasksCaseStyle.activityContentContainer} alignItems={"center"}>
              <Grid item sx={paidTasksCaseStyle.innerHeadline}>
                {paidTask.subject}
              </Grid>
              {paidTask.status !== PAID_TASK_STATUS_IN_REVIEW && (
                <Grid item>
                  <ReportProblemOutlined />
                </Grid>
              )}
              <Grid item sx={paidTasksCaseStyle.activityInfo}>
                {moment(paidTask.doneDeadline).format("DD.MM.YYYY")} |{" "}
                {paidTask.assignee ? paidTask.assignee.person.fullname : "Noch nicht zugeordnet"}
              </Grid>
              {actions && (
                <Grid item sx={paidTasksCaseStyle.activityActions}>
                  {actions}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PaidTaskCaseHead;
