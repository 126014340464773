import React, { useState } from "react";
import { Grid, Paper, Stack, Tab, Tabs, Typography } from "@mui/material";
import { ContentContainer } from "../Container/PageContentContainer";
import { navigationExtensionEmotionStyle } from "./navigationExtensionAllDataStyle";
import { HashLink as Link } from "react-router-hash-link";
import { AbstractCase } from "../../types/AbstractCase";
import CaseAdditionalClientsTooltipIcon from "../Case/CaseAdditionalClientsTooltipIcon/CaseAdditionalClientsTooltipIcon";

const NavigationExtensionCaseFileGenerate = ({ product }: { product: AbstractCase }) => {
  const [topOffset, setTopOffset] = useState(69);

  if (!product) {
    return null;
  }

  return (
    <Paper
      square
      ref={(el) => el && setTopOffset(el.getBoundingClientRect().top)}
      sx={navigationExtensionEmotionStyle.tabs({ top: topOffset })}
    >
      <ContentContainer size={"large"}>
        <Grid container>
          <Grid item xs={12} lg={4}>
            <Stack justifyContent={"center"} sx={{ marginLeft: "2rem" }}>
              <Stack direction={"row"} spacing={1}>
                <Typography>
                  <strong>{product.backofficeCase?.label}</strong>{" "}
                  {!!product.opposingParty && <>./. {product.opposingParty}</>}
                </Typography>
                <CaseAdditionalClientsTooltipIcon product={product} fontSize={"small"} />
              </Stack>
              <Typography>Unser Zeichen: {product.reference}</Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} lg={8}>
            <Tabs variant={"fullWidth"} scrollButtons="auto" value={false} centered>
              <Tab component={Link} to={"#contractFiles"} label={"Fertiger Schriftsatz"} />
              <Tab component={Link} to={"#casePdfGenerator"} label={"Individueller Schriftsatz"} />
            </Tabs>
          </Grid>
        </Grid>
      </ContentContainer>
    </Paper>
  );
};

export default NavigationExtensionCaseFileGenerate;
