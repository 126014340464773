import React from "react";
import * as PropTypes from "prop-types";
import _ from "lodash";
import Grid from "@mui/material/Grid";
import ValidatorSelect from "../Validator/ValidatorSelect";
import * as divorceStatus from "../../services/LeadStatus/StatusDivorce";
import * as settlementStatus from "../../services/LeadStatus/StatusSettlement";
import * as trafficStatus from "../../services/LeadStatus/StatusTraffic";
import * as documentStatus from "../../services/LeadStatus/StatusDocument";
import ButtonLoading from "../Button/ButtonLoading";
import MenuItem from "@mui/material/MenuItem";
import ApiClient from "../../services/ApiClient";

import { useSnackbar } from "notistack";
import useForm from "../../hooks/useForm";
import { formValue } from "../../services/formServiceFunctions";
import * as caseStatus from "../../services/LeadStatus/StatusCase";
import { getCaseLink } from "../../services/Product/ProductService";
import ContentBox from "../ContentBox/ContentBox";
import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Stack } from "@mui/material";
import { queryKeys } from "../../services/ReactQuery/reactQueryService";
import { useQueryClient } from "@tanstack/react-query";
import { scrollToAnchor } from "../../theme/commonStyles";
import { isOpponentLawyerDataComplete } from "../Case/CasePdfGenerator/casePdfGeneratorService";

const CaseFileGenerateForm = ({ product }) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const generatableContractFilesBase = [
    {
      value: "powerOfAttorney",
      label: "Vollmacht",
      isDisabled: (product) => {
        return product.leadStatus < caseStatus.STATUS_DATA_COMPLETED;
      },
    },
    {
      value: "powerOfAttorneyWithoutSignature",
      label: "Leere Vollmacht",
      isDisabled: (product) => {
        return product.leadStatus < caseStatus.STATUS_DATA_COMPLETED;
      },
    },
    {
      value: "claimLetter",
      label: "Außergerichtliches Schreiben",
      isDisabled: (product) => {
        return product.leadStatus < caseStatus.STATUS_DATA_COMPLETED;
      },
    },
    {
      value: "debtCollectionLetter",
      label: "Außergerichtliches Schreiben Inkasso",
      isDisabled: (product) => {
        return product.leadStatus < caseStatus.STATUS_CHECKED || !product.debtCollectionAgency;
      },
    },
    {
      value: "advocardFlexCoverageRequest",
      label: "FlexService Deckungsanfrage",
      isDisabled: (product) => {
        return (
          product.leadStatus < caseStatus.STATUS_DATA_COMPLETED ||
          product.insurance.specialTermsTag !== "wasAdvocardFlex"
        );
      },
    },
    {
      value: "coverLetterJudicialCoverageRequest",
      label: "Deckungsanfrage Gerichtsverfahren",
      isDisabled: (product) => product.leadStatus < caseStatus.STATUS_PREPARE_LAWSUIT,
    },
    {
      value: "statementOfDefenseWithApplication",
      label: "Verteidigungsanzeige mit Antrag",
      isDisabled: (product) => {
        return product.leadStatus < caseStatus.STATUS_LAWSUIT || !product.statementOfClaimFiledDate;
      },
    },
    {
      value: "statementOfDefenseWithApplicationNoDeadlineExtension",
      label: "Verteidigungsanzeige mit Antrag (ohne Fristverlängerung)",
      isDisabled: (product) => {
        return product.leadStatus < caseStatus.STATUS_LAWSUIT || !product.statementOfClaimFiledDate;
      },
    },
    {
      value: "statementOfDefense",
      label: "Verteidigungsanzeige ohne Antrag",
      isDisabled: (product) => {
        return product.leadStatus < caseStatus.STATUS_LAWSUIT || !product.statementOfClaimFiledDate;
      },
    },
    {
      value: "statementOfDefenseNoDeadlineExtension",
      label: "Verteidigungsanzeige ohne Antrag (ohne Fristverlängerung)",
      isDisabled: (product) => {
        return product.leadStatus < caseStatus.STATUS_LAWSUIT || !product.statementOfClaimFiledDate;
      },
    },
    {
      value: "declarationOfCompletion",
      label: "Erledigungserklärung",
      isDisabled: (product) => {
        return product.leadStatus < caseStatus.STATUS_LAWSUIT;
      },
    },
    {
      value: "abandonmentOfAction",
      label: "Klagerücknahme",
      isDisabled: (product) => {
        return product.leadStatus < caseStatus.STATUS_LAWSUIT;
      },
    },
    {
      value: "extrajudicialDeadlineExtension",
      label: "Außergerichtliche Fristverlängerung",
      isDisabled: (product) => {
        return product.leadStatus < caseStatus.STATUS_WAITING_FOR_FEEDBACK;
      },
    },
    {
      value: "assessmentOfCostsRequest",
      label: "Kostenfestsetzungsantrag",
      isDisabled: (product) => {
        return product.leadStatus < caseStatus.STATUS_LAWSUIT_COMPLETED;
      },
    },
    {
      value: "enforcementInstrument",
      label: "Zwangsvollstreckung: Versand Titel an Kunden",
      isDisabled: (product) => {
        return product.leadStatus < caseStatus.STATUS_DATA_COMPLETED;
      },
    },
    {
      value: "costCompensationRequest",
      label: "Kostenausgleichsantrag",
      isDisabled: (product) => {
        return product.leadStatus < caseStatus.STATUS_LAWSUIT_COMPLETED;
      },
    },
    {
      value: "formalNoticeCostsRequest",
      label: "Zahlungsaufforderung aus KFB",
      isDisabled: (product) => {
        return (
          product.leadStatus < caseStatus.STATUS_LAWSUIT_COMPLETED ||
          product.costBearingByOpponentFinalAbsolute === null ||
          product.courtCostsFinal === null ||
          isOpponentLawyerDataComplete(product)
        );
      },
    },
    {
      value: "formalNoticeCostsRequestLawyer",
      label: "Zahlungsaufforderung aus KFB an RA",
      isDisabled: (product) => {
        return (
          product.leadStatus < caseStatus.STATUS_LAWSUIT_COMPLETED ||
          product.costBearingByOpponentFinalAbsolute === null ||
          product.courtCostsFinal === null ||
          !isOpponentLawyerDataComplete(product)
        );
      },
    },
    {
      value: "formalPaymentNotice",
      label: "Zahlungsaufforderungsschreiben",
      isDisabled: (product) => {
        return product.leadStatus < caseStatus.STATUS_LAWSUIT_COMPLETED || !product.principalClaim;
      },
    },
    {
      value: "statusRequestCourt",
      label: "Sachstandsanfrage Gericht",
      isDisabled: (product) => {
        return product.leadStatus < caseStatus.STATUS_LAWSUIT;
      },
    },
    {
      value: "briefing",
      label: "Briefing TV Gerichtstermin",
      isDisabled: (product) => {
        return product.leadStatus !== caseStatus.STATUS_TRIAL || product.courtAppointments?.length < 1;
      },
    },
    {
      value: "notificationNewAddress",
      label: "Mitteilung neue Anschrift Mandant",
      isDisabled: (product) => {
        return product.leadStatus < caseStatus.STATUS_LAWSUIT;
      },
    },
    {
      value: "halfOfCourtFeeReimbursementRequest",
      label: "Antrag Gerichtskosten-Ausgleich",
      isDisabled: (product) => {
        return product.leadStatus < caseStatus.STATUS_LAWSUIT_COMPLETED;
      },
    },
  ];

  const commonCeneratableContractFiles = [
    ...generatableContractFilesBase,
    {
      value: "accessToInvestigationFile",
      label: "Akteneinsicht Ermittlungsakte",
      isDisabled: (product) => {
        return product.leadStatus < caseStatus.STATUS_WAITING_FOR_FEEDBACK || !product.responsibleProsecution;
      },
    },
  ];

  const generatableFiles = {
    divorce: [
      {
        value: "divorceApplicationComplete",
        label: "Scheidungsantrag",
        isDisabled: (divorce) => {
          return divorce.leadStatus < divorceStatus.STATUS_CHECKED;
        },
      },
      {
        value: "divorceApplication",
        label: "Scheidungsantrag (ohne VKH Anhänge)",
        isDisabled: (divorce) => {
          return divorce.hasProcessCostSupport === false || divorce.leadStatus < divorceStatus.STATUS_CHECKED;
        },
      },
      {
        value: "installmentOffer",
        label: "Ratenzahlungsangebot",
        isDisabled: (divorce) => {
          return divorce.leadStatus < divorceStatus.STATUS_DATA_COMPLETED;
        },
      },
      {
        value: "installmentPlan",
        label: "Ratenzahlungsplan",
        isDisabled: (divorce) => {
          return divorce.leadStatus < divorceStatus.STATUS_DATA_COMPLETED;
        },
      },
      {
        value: "legalEffect",
        label: "Scheidungsbeschluss mit Rechtskraftvermerk",
        isDisabled: (divorce) => {
          return divorce.leadStatus < divorceStatus.STATUS_DATE_SET;
        },
      },
      {
        value: "powerOfAttorney",
        label: "Vollmacht",
        isDisabled: (divorce) => {
          return divorce.leadStatus < divorceStatus.STATUS_POWER_OF_ATTORNEY_GRANTED;
        },
      },
      {
        value: "powerOfAttorneyWithoutSignature",
        label: "Leere Vollmacht",
        isDisabled: (divorce) => {
          return divorce.leadStatus < divorceStatus.STATUS_POWER_OF_ATTORNEY_GRANTED;
        },
      },
      {
        value: "briefing",
        label: "Briefing TV Scheidungstermin",
        isDisabled: (divorce) => {
          return divorce.leadStatus < divorceStatus.STATUS_DATE_SET;
        },
      },
      {
        value: "questionnairePensionAdjustment",
        label: "Übersendung Fragebogen Versorgungsausgleich",
        isDisabled: (divorce) => {
          return divorce.leadStatus < divorceStatus.STATUS_SUBMITTED;
        },
      },
      {
        value: "transmissionNotarisedAgreement",
        label: "Übersendung Notarvertrag",
        isDisabled: (divorce) => {
          return divorce.leadStatus < divorceStatus.STATUS_SUBMITTED;
        },
      },
      {
        value: "transmissionInquiredDocuments",
        label: "Übersendung geforderte Unterlagen",
        isDisabled: (divorce) => {
          return divorce.leadStatus < divorceStatus.STATUS_SUBMITTED;
        },
      },
      {
        value: "notificationMissingDocumentsPensionAdjustment",
        label: "Mitteilung fehlende Unterlagen Versorgungsausgleich",
        isDisabled: (divorce) => {
          return divorce.leadStatus < divorceStatus.STATUS_SUBMITTED;
        },
      },
      {
        value: "notificationNewAddress",
        label: "Mitteilung neue Anschrift Mandant",
        isDisabled: (divorce) => {
          return divorce.leadStatus < divorceStatus.STATUS_SUBMITTED;
        },
      },
      {
        value: "statusRequestCourt",
        label: "Sachstandsanfrage Gericht",
        isDisabled: (divorce) => {
          return divorce.leadStatus < divorceStatus.STATUS_SUBMITTED;
        },
      },
      {
        value: "requestDivorceDecision",
        label: "Anforderung Scheidungsbeschluss RKV",
        isDisabled: (divorce) => {
          return divorce.leadStatus < divorceStatus.STATUS_SUBMITTED;
        },
      },
    ],
    settlement: [
      {
        value: "dismissalProtectionSuitComplete",
        label: "Kündigungsschutzklage",
        isDisabled: (settlement) => {
          return settlement.leadStatus < settlementStatus.STATUS_CHECKED;
        },
      },
      {
        value: "powerOfAttorneySettlement",
        label: "Vollmacht",
        isDisabled: (settlement) => {
          return settlement.leadStatus < settlementStatus.STATUS_POWER_OF_ATTORNEY_GRANTED;
        },
      },
      {
        value: "powerOfAttorneySettlementWithoutSignature",
        label: "Leere Vollmacht",
        isDisabled: (settlement) => {
          return settlement.leadStatus < settlementStatus.STATUS_POWER_OF_ATTORNEY_GRANTED;
        },
      },
      {
        value: "briefing",
        label: "Briefing TV Gütetermin",
        isDisabled: (settlement) => {
          return settlement.leadStatus < settlementStatus.STATUS_DATE_SET_SETTLEMENT;
        },
      },
      {
        value: "transmissionInquiredDocuments",
        label: "Übersendung geforderte Unterlagen",
        isDisabled: (settlement) => {
          return settlement.leadStatus < settlementStatus.STATUS_SUBMITTED;
        },
      },
      {
        value: "cancellationAppointmentComparison",
        label: "Aufhebung Termin wegen Vergleich",
        isDisabled: (settlement) => {
          return settlement.leadStatus < settlementStatus.STATUS_DATE_SET_SETTLEMENT;
        },
      },
      {
        value: "transmissionEnforceableComparison",
        label: "Übersendung vollstreckbarer Vergleich",
        isDisabled: (settlement) => {
          return settlement.leadStatus < settlementStatus.STATUS_SUBMITTED;
        },
      },
      {
        value: "notificationNewAddress",
        label: "Mitteilung neue Anschrift Mandant",
        isDisabled: (settlement) => {
          return settlement.leadStatus < settlementStatus.STATUS_SUBMITTED;
        },
      },
    ],
    traffic: [
      {
        value: "returnOfRecordsPdf",
        label: "Rücksendung Akte",
        isDisabled: (traffic) => {
          return (
            traffic.leadStatus < trafficStatus.STATUS_POWER_OF_ATTORNEY_GRANTED ||
            !traffic.fineNoticeReference ||
            !traffic.responsibleAuthority
          );
        },
      },
      {
        value: "entitlementLetterPdf",
        label: "Bestellungsschreiben",
        isDisabled: (traffic) => {
          return traffic.leadStatus < trafficStatus.STATUS_POWER_OF_ATTORNEY_GRANTED || !traffic.responsibleAuthority;
        },
      },
      {
        value: "accessToRecordsMessage",
        label: "Einspruch/Akteneinsicht",
        isDisabled: (traffic) => {
          return traffic.leadStatus < trafficStatus.STATUS_CHECKED || !traffic.responsibleAuthority;
        },
      },
      {
        value: "powerOfAttorneyTraffic",
        label: "Vollmacht",
        isDisabled: (traffic) => {
          return traffic.leadStatus < trafficStatus.STATUS_POWER_OF_ATTORNEY_GRANTED;
        },
      },
      {
        value: "powerOfAttorneyTrafficWithoutSignature",
        label: "Leere Vollmacht",
        isDisabled: (traffic) => {
          return traffic.leadStatus < trafficStatus.STATUS_POWER_OF_ATTORNEY_GRANTED;
        },
      },
      {
        value: "briefing",
        label: "Briefing TV Hauptverhandlung",
        isDisabled: (traffic) => {
          return traffic.leadStatus < trafficStatus.STATUS_TRIAL_DATE_SET;
        },
      },
      {
        value: "transmissionInquiredDocumentsAuthority",
        label: "Übersendung geforderte Unterlagen Behörde",
        isDisabled: (traffic) => {
          return !traffic.responsibleAuthority;
        },
      },
      {
        value: "deadlineExtension",
        label: "Fristverlängerung Einspruchsbegründung Behörde",
        isDisabled: (traffic) => {
          return traffic.leadStatus < trafficStatus.STATUS_CHECKED;
        },
      },
      {
        value: "transmissionInquiredDocumentsCourt",
        label: "Übersendung geforderte Unterlagen Gericht",
        isDisabled: (traffic) => {
          return traffic.leadStatus < trafficStatus.STATUS_TRIAL;
        },
      },
      {
        value: "releaseFromPersonalAttendanceCourt",
        label: "Entbindung persönliches Erscheinen",
        isDisabled: (traffic) => {
          return (
            traffic.leadStatus < trafficStatus.STATUS_TRIAL ||
            !traffic.courtAppointments?.some((appointment) => !!appointment.courtDate?.appointmentDate)
          );
        },
      },
      {
        value: "appealWithdrawalAuthority",
        label: "Rücknahme Einspruch Behörde",
        isDisabled: (traffic) => {
          return traffic.leadStatus < trafficStatus.STATUS_CHECKED || !traffic.responsibleAuthority;
        },
      },
      {
        value: "appealWithdrawalProsecution",
        label: "Rückname Einspruch Staatsanwaltschaft",
        isDisabled: (traffic) => {
          return (
            traffic.leadStatus < trafficStatus.STATUS_CHECKED ||
            !traffic.responsibleProsecution ||
            !traffic.prosecutionReference
          );
        },
      },
      {
        value: "appealWithdrawalCourt",
        label: "Rückname Einspruch Gericht",
        isDisabled: (traffic) => {
          return traffic.leadStatus < trafficStatus.STATUS_TRIAL;
        },
      },
      {
        value: "requestVideoAuthority",
        label: "Videoanfrage bei Behörde",
        isDisabled: (traffic) => {
          return traffic.leadStatus < trafficStatus.STATUS_ACCESS_TO_RECORDS;
        },
      },
      {
        value: "requestReferenceProsecution",
        label: "Anfrage Aktenzeichen Staatsanwaltschaft",
        isDisabled: (traffic) =>
          !traffic.responsibleProsecution || !traffic.responsibleAuthority || !traffic.fineNoticeReference,
      },
      {
        value: "notificationNewAddress",
        label: "Mitteilung neue Anschrift Mandant",
        isDisabled: (traffic) => {
          return traffic.leadStatus < trafficStatus.STATUS_TRIAL;
        },
      },
    ],
    alimony: [
      {
        value: "alimony",
        label: "Unterhaltsanalyse",
        isDisabled: (alimony) => {
          return alimony.leadStatus < documentStatus.STATUS_COMPLETED;
        },
      },
    ],
    death: [
      {
        value: "deathAndInheritance",
        label: "Tod und Erbe",
        isDisabled: (death) => {
          return death.leadStatus < documentStatus.STATUS_COMPLETED;
        },
      },
    ],
    patientDecree: [
      {
        value: "patientDecree",
        label: "Patientenverfügung",
        isDisabled: (patientDecree) => {
          return patientDecree.leadStatus < documentStatus.STATUS_COMPLETED;
        },
      },
    ],
    powerOfAttorney: [
      {
        value: "powerOfAttorney",
        label: "Vorsorgevollmacht",
        isDisabled: (powerOfAttorney) => {
          return powerOfAttorney.leadStatus < documentStatus.STATUS_COMPLETED;
        },
      },
    ],
    dataLeakContract: [
      ...generatableContractFilesBase,
      {
        value: "dataLeakBriefing",
        label: "Zusatzbriefing TV Datenweitergabe",
        isDisabled: (product) => {
          return product.leadStatus !== caseStatus.STATUS_TRIAL || product.courtAppointments?.length < 1;
        },
      },
      {
        value: "dataLeakClientBriefing",
        label: "Zusatzbriefing Mandant Datenweitergabe",
        isDisabled: (product) => {
          return product.leadStatus !== caseStatus.STATUS_TRIAL || product.courtAppointments?.length < 1;
        },
      },
      {
        value: "schufaDataCopyRequest",
        label: "Forderungsschreiben",
        isDisabled: (product) =>
          product.leadStatus < caseStatus.STATUS_WAITING_FOR_FEEDBACK || product.leadStatus > caseStatus.STATUS_CHECKED,
      },
      {
        value: "coverLetterExtrajudicialCoverageRequest",
        label: "Deckungsanfrage außergerichtlich",
        isDisabled: (product) => product.leadStatus < caseStatus.STATUS_CHECKED,
      },
      {
        value: "statementOfClaim",
        label: "Klageschrift",
        isDisabled: (product) => {
          //this is inconsistent etm since statementOfClaim is a generic template in the backend. as soon as this is cleaned up
          //the data leak specific part should be removed. (its only needed as long as we have old cases without a personal affection anyways)
          return product.leadStatus < caseStatus.STATUS_PREPARE_LAWSUIT || !product.personalAffection.personalAffectionLawFirm
        },
      },
      {
        value: "personalAffectionLawFirm",
        label: "Schriftsatz persönliche Betroffenheit",
        isDisabled: (product) =>
          product.leadStatus > caseStatus.STATUS_TRIAL || product.leadStatus < caseStatus.STATUS_CHECKED,
      },
    ],
    eventContract: commonCeneratableContractFiles,
    membershipContract: commonCeneratableContractFiles,
    otherContract: commonCeneratableContractFiles,
    providerContract: commonCeneratableContractFiles,
    purchaseContract: commonCeneratableContractFiles,
    serviceContract: commonCeneratableContractFiles,
    subscriptionContract: commonCeneratableContractFiles,
    travelContract: commonCeneratableContractFiles,
    vehicleContract: commonCeneratableContractFiles,
    rentalContract: generatableContractFilesBase,
  };

  const onSubmit = async () => {
    const response = await ApiClient.post("files/generate/" + product.productClassName + "/" + product.id, {
      body: JSON.stringify({
        documentType: "pdf",
        documentIdentifier: values.fileType,
        sendEmailToCustomer: values.sendEmailToCustomer,
        showInGallery: values.showInGallery,
      }),
    });
    enqueueSnackbar(
      response && response.status === "ok"
        ? "Dokument wurde erfolgreich erstellt"
        : "Dokument konnte nicht erstellt werden",
      {
        variant: "custom",
        buttonType: "link",
        buttonLink: getCaseLink(product),
        buttonTarget: "_self",
        buttonText: "Zum Fall",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      }
    );
    await queryClient.invalidateQueries(queryKeys.collections("media_objects"));
  };

  const initialValues = { fileType: "", sendEmailToCustomer: false, showInGallery: false };
  const { values, handleChange, handleSubmit, isLoading } = useForm({
    initialValues,
    onSubmit,
  });

  const productGeneratableFiles = generatableFiles[product.productClassName];

  if (_.isEmpty(productGeneratableFiles)) {
    return null;
  }

  return (
    <>
      <Box sx={scrollToAnchor} id={"contractFiles"} />
      <ContentBox headline="Fertiger Schriftsatz">
        <form onSubmit={handleSubmit}>
          <Grid container alignItems={"center"} justifyContent={"center"} spacing={3}>
            <Grid item xs={12} md={6}>
              <ValidatorSelect
                label={"Welche Datei?"}
                name={"fileType"}
                value={formValue(values, "fileType")}
                helperText={"Art der Datei: z.B. Rechnung alle Gebühren"}
                onChange={handleChange}
              >
                {_.map(productGeneratableFiles, (fileData) => {
                  return (
                    <MenuItem key={fileData.value} value={fileData.value} disabled={fileData.isDisabled(product)}>
                      {fileData.label}
                    </MenuItem>
                  );
                })}
              </ValidatorSelect>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack direction={"row"} justifyContent={"space-evenly"}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Für Mandantschaft sichtbar</FormLabel>
                  <RadioGroup row={true} name={"showInGallery"} value={values.showInGallery} onChange={handleChange}>
                    <FormControlLabel
                      disabled={values.sendEmailToCustomer === "true"}
                      value={"true"}
                      control={<Radio />}
                      label={"Ja"}
                    />
                    <FormControlLabel
                      disabled={values.sendEmailToCustomer === "true"}
                      value={"false"}
                      control={<Radio />}
                      label={"Nein"}
                    />
                  </RadioGroup>
                </FormControl>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Mandantschaft benachrichtigen</FormLabel>
                  <RadioGroup
                    row={true}
                    name={"sendEmailToCustomer"}
                    value={values.sendEmailToCustomer}
                    onChange={(e) => {
                      handleChange(e);
                      handleChange({ target: { name: "showInGallery", value: "true" } });
                    }}
                  >
                    <FormControlLabel value={"true"} control={<Radio />} label={"Ja"} />
                    <FormControlLabel value={"false"} control={<Radio />} label={"Nein"} />
                  </RadioGroup>
                </FormControl>
              </Stack>
            </Grid>
            <Grid item>
              <ButtonLoading
                sx={{ width: "250px" }}
                isLoading={isLoading}
                disabled={isLoading}
                type={"submit"}
                color={"primary"}
                variant={"contained"}
              >
                Dokument generieren
              </ButtonLoading>
            </Grid>
          </Grid>
        </form>
      </ContentBox>
    </>
  );
};

CaseFileGenerateForm.propTypes = {
  product: PropTypes.object.isRequired,
};

export default CaseFileGenerateForm;
