import { AbstractCase } from "../../types/AbstractCase";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchCollection, queryKeys } from "../../services/ReactQuery/reactQueryService";
import { Box, Typography } from "@mui/material";
import { scrollToAnchor } from "../../theme/commonStyles";
import ContentBox from "../ContentBox/ContentBox";
import _ from "lodash";
import Activity from "./Activity";
import { Activity as ActivityType } from "../../types/Activity";
import DoneActivity from "./DoneActivity";
import ButtonLoading from "../Button/ButtonLoading";
import React, { useState } from "react";
import activitiesStyle from "./activitiesStyle";
import { useCurrentUser } from "../../provider/CurrentUserProvider";

interface CaseActivitiesProps {
  product: AbstractCase;
}

export default function CaseActivities({ product }: CaseActivitiesProps) {
  const currentUser = useCurrentUser();
  const [doneResultCount, setDoneResultCount] = useState(3);
  const queryClient = useQueryClient();
  let activitiesFilters: { case: string; deleted: boolean; pagination: boolean; assignedUser?: string } = {
    case: product.backofficeCase ? product.backofficeCase["@id"] : "",
    deleted: false,
    pagination: false,
  };
  if (currentUser.isExternal) {
    activitiesFilters.assignedUser = currentUser["@id"];
  }
  const { data: activities, isLoading } = useQuery(
    queryKeys.collection("activities", activitiesFilters),
    () => fetchCollection("activities", activitiesFilters),
    {
      enabled: !!product,
    }
  );

  if (isLoading || !activities) {
    return null;
  }

  const refreshActivities = () => queryClient.invalidateQueries(queryKeys.collection("activities", activitiesFilters));
  const openActivities: ActivityType<string>[] = _.filter(
    activities["hydra:member"],
    (activity: ActivityType<string>) => {
      return !activity.done;
    }
  );
  const allDoneActivities: ActivityType<string>[] = _.filter(
    activities["hydra:member"],
    (activity: ActivityType<string>) => {
      return activity.done;
    }
  );
  const doneActivities = _.slice(_.orderBy(allDoneActivities, ["doneTime"], ["desc"]), 0, doneResultCount);

  return (
    <>
      <Box sx={scrollToAnchor} id={"activities"} />
      <ContentBox headline="Anstehende Aktivitäten">
        {openActivities.length > 0 ? (
          openActivities.map((activity) => (
            <Box sx={activitiesStyle.activityContainer} key={activity.id}>
              <Activity activity={activity} product={product} updateActivities={refreshActivities} />
            </Box>
          ))
        ) : (
          <Box sx={activitiesStyle.faded}>Es ist keine Aktivität geplant</Box>
        )}
        <Box sx={activitiesStyle.horizontalLine} />
        <Typography sx={{ textAlign: "center" }} variant="h3">
          Letzte Aktivitäten
        </Typography>
        {doneActivities.length > 0 ? (
          doneActivities.map((doneActivity) => (
            <Box sx={activitiesStyle.activityContainer} key={doneActivity.id}>
              <DoneActivity
                productClassName={product.productClassName}
                activity={doneActivity}
                updateActivities={refreshActivities}
              />
            </Box>
          ))
        ) : (
          <Box sx={activitiesStyle.faded}>Keine erledigte Aktivität vorhanden</Box>
        )}
        {allDoneActivities.length > doneResultCount && (
          <ButtonLoading onClick={() => setDoneResultCount(doneResultCount + 10)}>Mehr anzeigen</ButtonLoading>
        )}
        {doneActivities.length > 3 && (
          <ButtonLoading onClick={() => setDoneResultCount(3)} className={activitiesStyle.button}>
            Weniger anzeigen
          </ButtonLoading>
        )}
      </ContentBox>
    </>
  );
}
