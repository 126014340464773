import React, { useState } from "react";
import * as PropTypes from "prop-types";
import useStyles from "./uploadFileStyles";
import classNames from "classnames";
import { DropzoneArea } from "mui-file-dropzone";
import MediaObjectFormModal from "./MediaObjectFormModal";
import { Box } from "@mui/material";
import DocumentClassLabelAiProvider from "../../../provider/DocumentClassLabelAiProvider";
import ActionIdentifierProvider from "../../../provider/ActionIdentifierProvider";

const FILE_SIZE_LIMIT_WARNING = 10_485_760;
const FILE_SIZE_LIMIT_EXCEEDED = 20_971_520;

const UploadFile = ({ open, closeDialog, product }) => {
  const classes = useStyles({});
  const [files, setFiles] = useState([]);

  const fileChange = async (files) => {
    setFiles(files);
  };

  const resetDialog = () => {
    setFiles([]);
    closeDialog();
  };

  const fileSize = files[0] ? files[0].size : 0;

  return (
    <DocumentClassLabelAiProvider>
      <ActionIdentifierProvider>
        <MediaObjectFormModal
          product={product}
          closeDialog={resetDialog}
          open={open}
          fileData={{ file: files[0] }}
          submitDisabled={!files[0] || fileSize >= FILE_SIZE_LIMIT_EXCEEDED}
        >
          <Box sx={{ textAlign: "center" }}>
            <div
              className={classNames(
                classes.fileContainer,
                fileSize > FILE_SIZE_LIMIT_WARNING ? classes.borderWarning : classes.border
              )}
            >
              <DropzoneArea
                onChange={fileChange}
                filesLimit={1}
                dropzoneText={"Datei hierher ziehen oder Fläche anklicken"}
                showFileNames
                showAlerts={false}
                maxFileSize={300000000}
                useChipsForPreview
                dropzoneClass={classes.dropZone}
              />
            </div>
            <br />
            {fileSize > FILE_SIZE_LIMIT_WARNING && fileSize < FILE_SIZE_LIMIT_EXCEEDED && (
              <div className={classes.warning}>
                Achtung: Die Datei ist über 10 MB groß. Wollen Sie diese trotzdem hochladen?
              </div>
            )}
            {fileSize >= FILE_SIZE_LIMIT_EXCEEDED && (
              <div className={classes.warning}>
                Achtung: Die Datei ist über 20 MB groß und kann nicht hochgeladen werden.
              </div>
            )}
          </Box>
        </MediaObjectFormModal>
      </ActionIdentifierProvider>
    </DocumentClassLabelAiProvider>
  );
};

UploadFile.propTypes = {
  open: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
};

export default UploadFile;
